<template>
  <div class="flex gap-4 relative">
    <div class="card py-4 px-4 max-w-full flex flex-col bg-gray-200 self-start gap-3 2xl:gap-4">
      <div class="info rounded-xl h-10 2xl:h-14 pl-4" v-if="showInfo">
        <div class="info__controls">
          <CheckboxSide
            class="rounded-l-xl w-4 2xl:w-5 p-1"
            disabled
            @click.native.stop
            @change="$emit('clicked', data.id)"
            :value="data.checked"
          />
        </div>
        <div
          class="info__general pl-3.5 pr-2.5 cursor-pointer gap-2"
        >
          <div class="label gap-1">
            <div class="name text-sm truncate">{{ data?.name || ""}}</div>
          </div>
          <div class="status flex flex-col w-full p-2">
            <div class="truncate max-w-full flex-shrink "> {{ data.title }} </div>
              <!-- fixed status which was missing  -->
              <DropdownMenu
                :value="data.status"
                value-attr="id"
                :options="statusList"
                trigger-class="card-short--check-status__dropdown px-3 rounded-2xl"
                :trigger-styles="{'background-color': data?.status.status_colour, maxWidth: '120px' }"
                :list-styles="{ maxWidth: '115px' }"
                :onSelect="(e) => $emit('statusChanged', e.option, data.id)"
              >
                  <template v-slot:selected>
                      <div class="w-full text-center text-base truncate max-w-full text-xs font-bold" style="font-size:0.85rem" :title="data?.status_name">
                          {{ data?.status.status_name }}
                      </div>
                  </template>   
                  <template v-slot:option="{ option }">
                      <span class="rounded-full w-4 border border-solid border-gray-300 flex-shrink-0 h-4" :style="{'background-color': option?.status_colour }"></span>
                      <div class="text-base truncate max-w-full font-bold" style="font-size:0.85rem" :title="option?.status_name" >
                          {{ option?.status_name }}
                      </div>
                  </template>
              </DropdownMenu>
          </div>
        </div>
      </div>
      <div class="actions gap-1">
        <!-- {{data.integration_source_id}} -->
        <div class="actions__item item--phone" :class="{'selected': EDDInfoSelected}" @click.stop="handleIconClick('eddInfoClick')" title="EDD Info" v-if="data?.integration_source_id === EDD_CHECK_ID">
          <eddInfoIcon class="cursor-pointer" />
        </div>
        <!-- {{data}} -->
        <div class="actions__item item--phone2" 
          @click.stop="handleIconClick('corporate-records-info')" title="Corporate Records Info"
          v-if="[CORPORATE_RECORDS_CHECK_ID, LEXIS_BRIDGER_CHECK_ID, SEARCH_ENGINE_CHECK_ID].includes(data?.integration_source_id)">
          <eddInfoIcon class="cursor-pointer" />
        </div>
        <div class="actions__item item--email" @click.stop="handleIconClick('email')" title="Email">
          <emailIcon class="icon w-1/2 h-1/2" />
        </div>
        <div class="actions__item item--email" @click.stop="handleIconClick('file-manager')" title="File Manager">
          <file-manager class="icon w-1/2 h-1/2"/>
        </div>
        <div class="actions__item item--phone" @click.stop="handleIconClick('logCall')" title="Call" v-if="![EDD_CHECK_ID, CORPORATE_RECORDS_CHECK_ID].includes(data?.integration_source_id)">
          <phoneIcon class="icon w-1/2 h-1/2" />
        </div>
        <div class="actions__item item--write" @click.stop="handleIconClick('note')" title="Note">
          <writingIcon class="icon w-1/2 h-1/2" />
        </div>
        <div class="actions__item item--task" @click.stop="handleIconClick('task')" title="Task">
          <taskIcon class="icon w-1/2 h-1/2" />
        </div>
        <div class="actions__item item--history" @click.stop="handleIconClick('history')" title="History">
          <historyIcon class="icon w-1/2 h-1/2" />
        </div>
        <!-- <div class="actions__item item--others" @click.stop="$emit('other')">
          <othersIcon class="icon w-1/2 h-1/2" />
        </div> -->
      </div>
    </div>
    <span id="close-button" class="absolute -right-4 text-brand font-bold ml-auto cursor-pointer hover:text-brand-hover" 
      style="stroke: var(--brand-color);stroke-width:2"
        @click="$emit('closeCollapsedCheck')" 
        v-if="!showInfo">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
        </svg>
    </span>
  </div>
</template>

<script>
import CheckboxSide from "@/components/sc-journey/checkbox-side.vue";

import phoneIcon from "@/assets/icons/sc-journey/phone.svg";
import emailIcon from "@/assets/icons/sc-journey/email.svg";
import writingIcon from "@/assets/icons/sc-journey/writing.svg";
import taskIcon from "@/assets/icons/sc-journey/task.svg";
import historyIcon from "@/assets/icons/sc-journey/history.svg";
import DropdownMenu from '@shared/case-entities/components/DropdownMenu.vue'
// import othersIcon from "@/assets/icons/sc-journey/others.svg";
import FileManager from "@shared/assets/svg/file-manager.svg"
import eddInfoIcon from "@shared/assets/icons/sc-journey/edd-info.svg";

import { EDD_CHECK_ID, CORPORATE_RECORDS_CHECK_ID, LEXIS_BRIDGER_CHECK_ID, SEARCH_ENGINE_CHECK_ID } from "../../utils/constants";
import { EventBus } from "@/main.js";

export default {
  name: "card-short",
  components: {
    phoneIcon,
    emailIcon,
    writingIcon,
    taskIcon,
    historyIcon,
    DropdownMenu,
    // othersIcon,
    CheckboxSide,
    FileManager,
    eddInfoIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    statusList: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
    showInfo: {
      type: Boolean,
      default: true,
    }
  },
  data() { 
    return {
      EDD_CHECK_ID,
      CORPORATE_RECORDS_CHECK_ID,
      LEXIS_BRIDGER_CHECK_ID,
      SEARCH_ENGINE_CHECK_ID,
      EDDInfoSelected: false,
    }
  },
  mounted() {
    EventBus.$on("unSelectEddInfoIcon", (flag)=> {
      this.EDDInfoSelected = flag
    })
  },
  watch: {
    data(val){
      console.log("watch val",val)
    }
  },
  methods: {
    // eddInfoClick() {
    //   this.$emit('eddInfoIconClick');
    //   this.selected = !this.selected;
    // }
    handleIconClick(type){
      switch(type) {
        case 'eddInfoClick': {
          this.EDDInfoSelected = !this.selected;
          this.$emit('eddInfoIconClick');
          break;
        }
        case 'corporate-records-info': {
          this.EDDInfoSelected = false;
          this.$emit('corporate-records-info');
          break;
        }
        case 'email' : {
          this.EDDInfoSelected = false;
          this.$emit('email');
          break;
        }
        case 'file-manager' : {
          this.EDDInfoSelected = false;
          this.$emit('file-manager');
          break;
        }
        case 'logCall' : {
          this.EDDInfoSelected = false;
          this.$emit('logCall');
          break;
        }
        case 'note' : {
          this.EDDInfoSelected = false;
          this.$emit('note');
          break;
        }
         case 'task' : {
          this.EDDInfoSelected = false;
          this.$emit('task');
          break;
        }
        case 'history' : {
          this.EDDInfoSelected = false;
          this.$emit('history');
          break;
        }

      }
    }
  }
};
</script>
<style>
.slotted-dropdown__trigger.card-short--check-status__dropdown {
  color: #fff
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.card {
  // background-color: #fff;
  display: flex;
  align-items: center;
  overflow: visible;
  flex-direction: column;
  align-items: stretch;
  .info {
    background-color: #dfdfdf;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    &__controls {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
    &__general {
      flex-grow: 1;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      .label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        padding: 0;
        overflow: hidden;
        .name {
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icons {
          display: inline-flex;
          align-items: center;
          transform: translateY(-50%);
          .count {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: bold;
            border-radius: 50%;
            background-color: #0d69d5;
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--theme-color-main);
      border-radius: 50%;
      cursor: pointer;
      flex-shrink: 0;
      @apply h-8 w-8 2xl:h-9 2xl:w-9;
      .icon {
        color: white;
        path {
          fill: #fff;
        }
      }
      // &:hover {
      //   background-color: var(--theme-color-main);
      //   @apply h-9 w-9 2xl:h-10 2xl:w-10;
      // }
    }
   
  }
  .selected {
        background-color:#0d69d5;
        @apply h-9 w-9 2xl:h-10 2xl:w-10;
      }
}
::v-deep {
  .multiselect {
    .multiselect__single,
    .multiselect__option {
      font-size: 10px;
    }
    .multiselect__content-wrapper{
      width: 100% 
    }
  }
}
</style>